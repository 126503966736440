import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';

const Category = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
`;

const CategoryLink = ({ category }) => (
  <Category to={`/categories/${kebabCase(category)}`}>{category}</Category>
);

CategoryLink.propTypes = {
  category: PropTypes.string.isRequired,
};

export default CategoryLink;
